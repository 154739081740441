import { template } from "@ember/template-compiler";
import PixStars from '@1024pix/pix-ui/components/pix-stars';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import maxBy from 'lodash/maxBy';
import sumBy from 'lodash/sumBy';
import ChartCard from '../../ui/chart-card';
import ParticipantsByStageBar from './participants-by-stage-bar';
import ParticipantsByStageLoader from './participants-by-stage-loader';
export default class ParticipantsByStage extends Component {
    @service
    store;
    @tracked
    data = [];
    @tracked
    totalStage = [];
    @tracked
    loading = true;
    constructor(...args1){
        super(...args1);
        const { campaignId: campaignId1 } = this.args;
        const adapter1 = this.store.adapterFor('campaign-stats');
        adapter1.getParticipationsByStage(campaignId1).then((response1)=>{
            const { data: data1 } = response1.data.attributes;
            const maxValue1 = maxBy(data1, 'value').value;
            const totalValues1 = sumBy(data1, 'value');
            this.totalStage = data1.length - 1;
            this.data = data1.map((stage1, index1)=>{
                const masteryRate1 = totalValues1 !== 0 ? stage1.value / totalValues1 : 0;
                const width1 = maxValue1 !== 0 ? Math.round((stage1.value / maxValue1) * 100) : 0;
                return {
                    index: index1,
                    id: stage1.id,
                    value: stage1.value,
                    title: stage1.title,
                    description: stage1.description,
                    masteryRate: masteryRate1,
                    barWidth: htmlSafe(`width: ${width1}%`),
                    tooltip: buildTooltipText(stage1.title, stage1.description),
                    displayTooltip: stage1.title || stage1.description
                };
            });
            this.loading = false;
        });
    }
    @action
    onClickBar(id1) {
        if (!this.args.onSelectStage) return;
        this.args.onSelectStage(id1);
    }
    static{
        template(`
    <ChartCard @title={{t "charts.participants-by-stage.title"}} ...attributes>
      {{#if this.loading}}
        <ParticipantsByStageLoader />
      {{else}}
        <ul class="participants-by-stage__wrapper">
          {{#each this.data as |stage index|}}
            <li class="participants-by-stage">
              <PixStars
                @count={{stage.index}}
                @total={{this.totalStage}}
                @color="blue"
                @alt={{t "common.result.stages" count=stage.index total=this.totalStage}}
                class="participants-by-stage__stars"
              />
              <div class="participants-by-stage__values">
                {{t "charts.participants-by-stage.participants" count=stage.value}}
              </div>
              {{#if stage.displayTooltip}}
                <PixTooltip
                  @id="chart-stage-{{index}}"
                  @position="bottom-right"
                  @isWide={{true}}
                  class="participants-by-stage__container"
                >
                  <:triggerElement>
                    <ParticipantsByStageBar
                      @onClickBar={{this.onClickBar}}
                      @stageId={{stage.id}}
                      @barWidth={{stage.barWidth}}
                      tabindex="0"
                      aria-describedby="chart-stage-{{index}}"
                    >
                      {{t "common.result.percentage" value=stage.masteryRate}}
                    </ParticipantsByStageBar>
                  </:triggerElement>
                  <:tooltip>
                    {{stage.tooltip}}
                  </:tooltip>
                </PixTooltip>
              {{else}}
                <div class="participants-by-stage__container">
                  <ParticipantsByStageBar
                    @onClickBar={{this.onClickBar}}
                    @stageId={{stage.id}}
                    @barWidth={{stage.barWidth}}
                  >
                    {{t "common.result.percentage" value=stage.masteryRate}}
                  </ParticipantsByStageBar>
                </div>
              {{/if}}
            </li>
          {{/each}}
        </ul>
      {{/if}}
    </ChartCard>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
function buildTooltipText(title1, description1) {
    let text1 = title1 ? `<strong>${title1}</strong>` : '';
    text1 += description1 ? `<div>${description1}</div>` : '';
    return htmlSafe(text1);
}
