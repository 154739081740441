import { template } from "@ember/template-compiler";
import PixBanner from '@1024pix/pix-ui/components/pix-banner';
import dayjs from 'dayjs';
import { t } from 'ember-intl';
import { STATUSES } from 'pix-orga/models/organization-places-lot.js';
function getLastActivePlacesLot(placesLots1) {
    return placesLots1.filter((placesLot1)=>placesLot1.status === STATUSES.ACTIVE).sort((placesLotA1, placesLotB1)=>placesLotB1.expirationDate - placesLotA1.expirationDate);
}
function getCountdDownDays(placesLots1) {
    const [lastActiveLot1] = getLastActivePlacesLot(placesLots1);
    if (!lastActiveLot1) return;
    return dayjs(lastActiveLot1.expirationDate).diff(dayjs(), 'day');
}
function isAlertVisible(placesLots1) {
    if (!Array.isArray(placesLots1)) return false;
    const hasPendingLots1 = placesLots1.some((placesLot1)=>placesLot1.status === STATUSES.PENDING);
    if (hasPendingLots1) return false;
    const [lastActiveLot1] = getLastActivePlacesLot(placesLots1);
    if (!lastActiveLot1) return false;
    return dayjs(lastActiveLot1.expirationDate).isBefore(dayjs().add(30, 'days'));
}
export default template(`
  {{#if (isAlertVisible @placesLots)}}
    <PixBanner class="places-lots-alert" @type="warning" @withIcon="true">
      {{t "banners.last-places-lot-available.message" days=(getCountdDownDays @placesLots)}}
    </PixBanner>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
