import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
export default class CampaignType extends Component {
    @service
    intl;
    get picto() {
        const { campaignType: campaignType1 } = this.args;
        return campaignType1 === 'ASSESSMENT' ? 'tachometer' : 'person-export';
    }
    get pictoCssClass() {
        const classes1 = [];
        const { campaignType: campaignType1 } = this.args;
        classes1.push(campaignType1 === 'ASSESSMENT' ? 'campaign-type__icon-assessment' : 'campaign-type__icon-profile-collection');
        if (this.args.big) {
            classes1.push(classes1[0] + '--big');
        }
        return classes1.join(' ');
    }
    get pictoAriaHidden() {
        return !this.args.hideLabel;
    }
    get pictoTitle() {
        return this.args.hideLabel ? this.label : null;
    }
    get label() {
        const { campaignType: campaignType1, labels: labels1 } = this.args;
        return this.intl.t(labels1[campaignType1]);
    }
    static{
        template(`
    <span class="campaign-type">
      <FaIcon
        class="{{this.pictoCssClass}}"
        @icon="{{this.picto}}"
        @prefix="fapix"
        aria-hidden={{this.pictoAriaHidden}}
        @title={{this.pictoTitle}}
        ...attributes
      />
      {{#unless @hideLabel}}
        <span class="campaign-type__label">{{this.label}}</span>
      {{/unless}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
