export const fapixInboxIn = {
  prefix: 'fapix',
  iconName: 'inbox-in',
  icon: [
    28,
    32,
    [],
    '',
    'M12.2188 4C11.7969 4 11.4219 4.375 11.4219 4.84375V11.5H8.51562C7.67188 11.5 7.25 12.5312 7.85938 13.0938L13.1562 18.7188C13.5312 19.0938 14.1406 19.0938 14.5156 18.7656L20.0938 13.1406C20.7031 12.5312 20.2812 11.5 19.4375 11.5H16.25V4.84375C16.25 4.375 15.875 4 15.4531 4H12.2188ZM26.9844 19.8906L22.5312 14.3594C22.3438 14.125 21.9219 14.0781 21.6875 14.3125L20.375 15.6719C20.1875 15.8594 20.1875 16.1875 20.375 16.4219L22.4375 19H18.125L16.625 22H11.375L9.875 19H5.51562L7.57812 16.4688C7.76562 16.2344 7.71875 15.9062 7.53125 15.7188L6.26562 14.3594C6.03125 14.0781 5.60938 14.125 5.42188 14.3594L0.96875 19.8906C0.640625 20.3125 0.5 20.7812 0.5 21.2969V25.75C0.5 27.0156 1.48438 28 2.75 28H25.25C26.4688 28 27.5 27.0156 27.5 25.75V21.2969C27.5 20.7812 27.3125 20.3125 26.9844 19.8906Z',
  ],
};
