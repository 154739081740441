import { template } from "@ember/template-compiler";
import PixBanner from '@1024pix/pix-ui/components/pix-banner';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import getService from '../../helpers/get-service.js';
function shouldDisplayBanner(session1) {
    const localeNotSupported1 = session1?.data?.localeNotSupported;
    const localeNotSupportedBannerClosed1 = session1?.data?.localeNotSupportedBannerClosed;
    return localeNotSupported1 && !localeNotSupportedBannerClosed1;
}
export default template(`
  {{#let (getService "service:session") as |session|}}
    {{#if (shouldDisplayBanner session)}}
      <PixBanner
        @type="information"
        @canCloseBanner="true"
        @onCloseBannerTriggerAction={{fn session.updateDataAttribute "localeNotSupportedBannerClosed" true}}
      >
        {{t "banners.language-availability.message"}}
      </PixBanner>
    {{/if}}
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
