import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import { action } from '@ember/object';
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
export default class CampaignArchivedBanner extends Component {
    @service
    store;
    @service
    notifications;
    @service
    intl;
    @service
    currentUser;
    @action
    async unarchiveCampaign() {
        try {
            const campaign1 = this.store.peekRecord('campaign', this.args.campaign.id);
            await campaign1.unarchive();
        } catch (err1) {
            this.notifications.sendError(this.intl.t('api-error-messages.global'));
        }
    }
    get displayUnarchiveButton() {
        const isCurrentUserAdmin1 = this.currentUser.prescriber.isAdminOfTheCurrentOrganization;
        const isCurrentUserOwnerOfTheCampaign1 = parseInt(this.currentUser.prescriber.id) === this.args.campaign.ownerId;
        const isCurrentUserAllowedToUnarchiveCampaign1 = isCurrentUserAdmin1 || isCurrentUserOwnerOfTheCampaign1;
        return isCurrentUserAllowedToUnarchiveCampaign1;
    }
    static{
        template(`
    {{#if @campaign.isArchived}}
      <div class="campaign-archived-banner">
        <div class="campaign-archived-banner__text">
          <FaIcon class="campaign-archived-banner__icon" @icon="box-archive" />
          <span>{{t "pages.campaign.archived"}}</span>
        </div>
        {{#if this.displayUnarchiveButton}}
          <PixButton @triggerAction={{this.unarchiveCampaign}} @variant="transparent-dark" @isBorderVisible={{true}}>
            {{t "pages.campaign.actions.unarchive"}}
          </PixButton>
        {{/if}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
