export const fapixTicket = {
  prefix: 'fapix',
  iconName: 'ticket',
  icon: [
    16,
    11,
    [],
    '',
    'M10.9375 4.4375H4.8125V7.0625H10.9375V4.4375ZM11.5938 3.125C11.9492 3.125 12.25 3.42578 12.25 3.78125V7.71875C12.25 8.10156 11.9492 8.375 11.5938 8.375H4.15625C3.77344 8.375 3.5 8.10156 3.5 7.71875V3.78125C3.5 3.42578 3.77344 3.125 4.15625 3.125H11.5938ZM15.5312 4.65625C14.9023 4.65625 14.4375 5.14844 14.4375 5.75C14.4375 6.37891 14.9023 6.84375 15.5312 6.84375H15.75V9.6875C15.75 10.4258 15.1484 11 14.4375 11H1.3125C0.574219 11 0 10.4258 0 9.6875V6.84375H0.21875C0.820312 6.84375 1.3125 6.37891 1.3125 5.75C1.3125 5.14844 0.820312 4.65625 0.21875 4.65625H0V1.8125C0 1.10156 0.574219 0.5 1.3125 0.5H14.4375C15.1484 0.5 15.75 1.10156 15.75 1.8125V4.65625H15.5312ZM14.4375 3.61719V1.8125H1.3125V3.61719C2.07812 4.02734 2.625 4.82031 2.625 5.75C2.625 6.70703 2.07812 7.5 1.3125 7.91016V9.6875H14.4375V7.91016C13.6445 7.5 13.125 6.70703 13.125 5.75C13.125 4.82031 13.6445 4.02734 14.4375 3.61719Z',
  ],
};
