import { template } from "@ember/template-compiler";
import PixFilterBanner from '@1024pix/pix-ui/components/pix-filter-banner';
import PixMultiSelect from '@1024pix/pix-ui/components/pix-multi-select';
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import PixStars from '@1024pix/pix-ui/components/pix-stars';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import DivisionsFilter from '../../ui/divisions-filter';
import GroupsFilter from '../../ui/groups-filter';
import SearchInputFilter from '../../ui/search-input-filter';
export default class ParticipationFilters extends Component {
    @service
    intl;
    @service
    currentUser;
    @tracked
    stages = [];
    @tracked
    isStagesLoading = true;
    @tracked
    badges = [];
    @tracked
    isBadgesLoading = true;
    constructor(){
        super(...arguments);
        Promise.resolve(this.args.campaign.stages).then((stages1)=>{
            this.stages = stages1;
            this.isStagesLoading = false;
        });
        Promise.resolve(this.args.campaign.badges).then((badges1)=>{
            this.badges = badges1;
            this.isBadgesLoading = false;
        });
    }
    get certificabilityOptions() {
        return [
            {
                value: 'eligible',
                label: this.intl.t('pages.sco-organization-participants.table.column.is-certifiable.eligible')
            },
            {
                value: 'non-eligible',
                label: this.intl.t('pages.sco-organization-participants.table.column.is-certifiable.non-eligible')
            }
        ];
    }
    get isClearFiltersButtonDisabled() {
        return (!this.args.selectedStatus && (!this.displaySearchFilter || !this.args.searchFilter) && (!this.displayDivisionFilter || this.args.selectedDivisions.length === 0) && (!this.displayGroupsFilter || this.args.selectedGroups.length === 0) && (!this.displayStagesFilter || this.args.selectedStages.length === 0) && (!this.displayBadgesFilter || this.args.selectedBadges.length === 0) && (!this.displayCertificabilityFilter || !this.args.selectedCertificability));
    }
    get displayFilters() {
        return (this.displayStagesFilter || this.displayBadgesFilter || this.displayDivisionFilter || this.displayStatusFilter || this.displayGroupsFilter || this.displayCertificabilityFilter || this.displaySearchFilter);
    }
    get displayCertificabilityFilter() {
        const { isTypeAssessment: isTypeAssessment1 } = this.args.campaign;
        return !isTypeAssessment1 && !this.args.isHiddenCertificability;
    }
    get displayStagesFilter() {
        if (this.isStagesLoading) return false;
        const { isTypeAssessment: isTypeAssessment1, hasStages: hasStages1 } = this.args.campaign;
        return !this.args.isHiddenStages && isTypeAssessment1 && hasStages1;
    }
    get displayBadgesFilter() {
        if (this.isBadgesLoading) return false;
        const { isTypeAssessment: isTypeAssessment1, hasBadges: hasBadges1 } = this.args.campaign;
        return !this.args.isHiddenBadges && isTypeAssessment1 && hasBadges1;
    }
    get displayDivisionFilter() {
        return this.currentUser.isSCOManagingStudents;
    }
    get displayGroupsFilter() {
        return this.currentUser.isSUPManagingStudents && !this.args.isHiddenGroup;
    }
    get displayStatusFilter() {
        return !this.args.isHiddenStatus;
    }
    get displaySearchFilter() {
        return !this.args.isHiddenSearch;
    }
    get stageOptions() {
        const totalStage1 = this.stages.length - 1;
        return this.stages.map((stage1, index1)=>({
                value: stage1.id,
                reachedStage: index1,
                totalStage: totalStage1,
                label: this.intl.t('common.result.stages', {
                    count: index1,
                    total: totalStage1
                })
            }));
    }
    get badgeOptions() {
        return this.badges.map(({ id: id1, title: title1 })=>({
                value: id1,
                label: title1
            }));
    }
    get statusOptions() {
        const { isTypeAssessment: isTypeAssessment1, type: type1 } = this.args.campaign;
        const statuses1 = isTypeAssessment1 ? [
            'STARTED',
            'TO_SHARE',
            'SHARED'
        ] : [
            'TO_SHARE',
            'SHARED'
        ];
        return statuses1.map((status1)=>{
            const label1 = this.intl.t(`components.participation-status.${status1}-${type1}`);
            return {
                value: status1,
                label: label1
            };
        });
    }
    @action
    onSelectStage(stages1) {
        this.args.onFilter('stages', stages1);
    }
    @action
    onSelectGroup(groups1) {
        this.args.onFilter('groups', groups1);
    }
    @action
    onSelectStatus(status1) {
        this.args.onFilter('status', status1);
    }
    @action
    onSelectBadge(badges1) {
        this.args.onFilter('badges', badges1);
    }
    @action
    onSelectDivision(divisions1) {
        this.args.onFilter('divisions', divisions1);
    }
    @action
    onSelectCertificability(certificability1) {
        this.args.onFilter('certificability', certificability1);
    }
    static{
        template(`
    {{#if this.displayFilters}}
      <PixFilterBanner
        @title={{t "common.filters.title"}}
        class="participant-filter-banner hide-on-mobile"
        aria-label={{t "pages.campaign-results.filters.aria-label"}}
        @details={{t "pages.campaign-results.filters.participations-count" count=@rowCount}}
        @clearFiltersLabel={{t "common.filters.actions.clear"}}
        @onClearFilters={{@onResetFilter}}
        @isClearFilterButtonDisabled={{this.isClearFiltersButtonDisabled}}
      >
        {{#if this.displayStatusFilter}}
          <PixSelect
            @screenReaderOnly={{true}}
            @options={{this.statusOptions}}
            @onChange={{this.onSelectStatus}}
            @value={{@selectedStatus}}
            @placeholder={{t "pages.campaign-results.filters.type.status.empty"}}
            @hideDefaultOption={{false}}
          >
            <:label>{{t "pages.campaign-results.filters.type.status.title"}}</:label>
          </PixSelect>
        {{/if}}
        {{#if this.displayDivisionFilter}}
          <DivisionsFilter @model={{@campaign}} @selected={{@selectedDivisions}} @onSelect={{this.onSelectDivision}} />
        {{/if}}
        {{#if this.displayGroupsFilter}}
          <GroupsFilter @campaign={{@campaign}} @onSelect={{this.onSelectGroup}} @selectedGroups={{@selectedGroups}} />
        {{/if}}
        {{#if this.displaySearchFilter}}
          <SearchInputFilter
            @field="search"
            @value={{@searchFilter}}
            @placeholder={{t "common.filters.fullname.placeholder"}}
            @label={{t "common.filters.fullname.label"}}
            @triggerFiltering={{@onFilter}}
          />
        {{/if}}
        {{#if this.displayStagesFilter}}
          <PixMultiSelect
            @placeholder={{t "pages.campaign-results.filters.type.stages"}}
            @screenReaderOnly={{true}}
            @onChange={{this.onSelectStage}}
            @values={{@selectedStages}}
            @options={{this.stageOptions}}
            @className="participant-filter-banner__stages-select"
          >
            <:label>{{t "pages.campaign-results.filters.type.stages"}}</:label>
            <:default as |stage|>
              <PixStars
                @count={{stage.reachedStage}}
                @total={{stage.totalStage}}
                @alt={{stage.label}}
                @color="blue"
                class="participant-filter-banner__stars"
              />
            </:default>
          </PixMultiSelect>
        {{/if}}
        {{#if this.displayBadgesFilter}}
          <PixMultiSelect
            @placeholder={{t "pages.campaign-results.filters.type.badges"}}
            @screenReaderOnly={{true}}
            @onChange={{this.onSelectBadge}}
            @values={{@selectedBadges}}
            @options={{this.badgeOptions}}
            @className="participant-filter-banner__badges"
          >
            <:label>{{t "pages.campaign-results.filters.type.badges"}}</:label>
            <:default as |badge|>{{badge.label}}</:default>
          </PixMultiSelect>
        {{/if}}
        {{#if this.displayCertificabilityFilter}}
          <PixSelect
            @options={{this.certificabilityOptions}}
            @value={{@selectedCertificability}}
            @onChange={{this.onSelectCertificability}}
            @screenReaderOnly={{true}}
            @placeholder={{t "components.certificability.placeholder-select"}}
          >
            <:label>{{t "pages.sco-organization-participants.filter.certificability.label"}}</:label>
          </PixSelect>
        {{/if}}
      </PixFilterBanner>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
